<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">审核管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">课程审核</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="ovy-a">
          <div class="flexcb">
            <h3 style="padding-bottom: 20px">课程基本信息</h3>
            <el-button v-show="stu == 'look'" size="mini" class="bgc-bv" @click="doGoBack">返回</el-button>
          </div>
          <div>
            <el-form ref="ruleForm" label-width="10rem" class="courseBox">
              <el-form-item label="课程名称：" class="form-cell">
                <p>{{ ruleForm.courseName }}</p>
              </el-form-item>
              <el-form-item v-show="ruleForm.trainTypeNamePath" label="培训类型：" class="form-cell">
                <p>{{ ruleForm.trainTypeNamePath || "" }}</p>
              </el-form-item>
              <el-form-item v-show="ruleForm.industryNamePath" label="行业类型：" class="form-cell">
                <p>{{ ruleForm.industryNamePath || "" }}</p>
              </el-form-item>
              <el-form-item v-show="ruleForm.postName" label="岗位类型：" class="form-cell">
                <p>{{ ruleForm.postName || "" }}</p>
              </el-form-item>
              <el-form-item v-show="ruleForm.occupationNamePath" label="职业/工种：" class="form-cell">
                <p>{{ ruleForm.occupationNamePath || "" }}</p>
              </el-form-item>
              <el-form-item v-show="ruleForm.trainLevelName" label="培训等级：" class="form-cell">
                <p>{{ ruleForm.trainLevelName || "" }}</p>
              </el-form-item>
            </el-form>
            <el-form ref="ruleForm" label-width="10rem">
              <el-form-item label="课程详情：" prop="context" class="form-item">
                <div v-html="ruleForm.context" ref="editor" style="width: 100%; position: relative; z-index: 2" />
              </el-form-item>
              <el-form-item label="课程图片：" prop="thumbnail">
                <div class="img-el-upload" style="width: 256px">
                  <el-image :src="ruleForm.thumbnail || require('@/assets/develop.png')" fit="contain"></el-image>
                </div>
              </el-form-item>
              <el-form-item label="讲师备注：" prop="lecturer">
                <p>{{ ruleForm.lecturer || "无" }}</p>
              </el-form-item>
              <el-form-item label="备注：" prop="remark">
                <p>{{ ruleForm.remark || "无" }}</p>
              </el-form-item>
            </el-form>
          </div>
          <h3 style="padding-bottom: 10px">课程章节</h3>
          <div>
            <span style="padding-bottom: 10px"> 视频累计时长:{{ ruleForm.durationStr }}</span>
            <span style="padding: 10px">课件总数:{{ ruleForm.kpointTotalNum }}</span>
            <span style="padding: 10px">总课时数:{{ assembleClassHourNum(ruleForm.duration) }}课时</span>
            <div style="display:flex;align-items: center; color: red">
              <span>课时时长：</span>
              <el-select v-model="ruleForm.convertClassHour" placeholder="请选择" style="width: 130px;" size="small">
                <el-option v-for="item in convertClassHourList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
              <!-- <el-button class="bgc-bv" style="margin-left: 10px;" size="mini" @click="saveConvertClassHour">保存</el-button> -->
            </div>
          </div> 
          <div>
            <el-button type="text" @click="setAllExpand">一键展开/折叠</el-button>
            <el-button class="bgc-bv" size="mini" @click="batchAudit">批量审核通过</el-button>
            <el-button class="bgc-bv" size="mini" @click="batchAuditPass">批量审核不通过</el-button>
          </div>
          <el-tree :data="data" ref="tree" node-key="courseKpointId" :default-expand-all="ssss"
            :props="defaultProps" v-if="ssss1">
            <div class="custom-tree-node" slot-scope="{ data }">
              <p v-if="data.level == 1">
                <span style="width: 44px; text-align: left">
                  <span class="level" style="margin-right: 10px">
                    {{ data.chapterNum }}
                  </span>
                </span>
                <span style="width: 33.6rem;">{{ data.catalogName  }}</span>
                <!-- <span v-text="data.label" style="margin-right: 100px" :title="data.label"></span> -->
                <span style="margin-right: 5px;">{{ chapterLessonPeriod(data) }}课时</span>
                <span>{{ chapterDuration(data) }}</span>
              </p>
              <template v-else-if="data.level == 2">
                <span style="width: 44px; text-align: left">
                  <span class="level" style="margin-right: 10px">
                    {{ data.chapterNum }}
                  </span>
                </span>
                <span v-if="data.list.length > 0" style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  ">
                  <i class="courseIcon" v-if="data.list[0].kpointId"></i>
                  <i class="paperIcon" v-else-if="data.list[0].paperId"></i>
                </span>
                <span :title="data.catalogName" class="textellipsis" v-text="data.catalogName"></span>
                <span style="margin-right: 5px;" v-if="data.list[0].kpointId"> {{ sectionLessonPeriod(data.kpointDuration) }}课时</span>
                <span>{{ data.kpointDurationStr }}</span>
                <el-button type="text" v-if="data.showTrySeeBtn" @click="showVideo(data)" size="mini"
                  style="margin: 0 1rem">预览</el-button>
                <el-radio-group v-show="!data.hidden" v-model="data.auditStates" class="set-food-select" size="mini"
                  value-key="specId" @change="change(data)">
                  <el-radio label="30">通过</el-radio>
                  <el-radio label="40">不通过</el-radio>
                </el-radio-group>
                <div class="limitput">
                  <el-input v-model="data.reason" size="small" v-show="data.datainput == true" style="margin-left: 10px"
                    maxlength="50" show-word-limit />
                </div>
              </template>
              <template v-else>
                <span class="textellipsis" :title="data.title + ':' + data.catalogName">{{ data.title }}：{{
                  data.catalogName ||
                  (data.title == "授课讲师" ? "无讲师" :
                    data.title == "源课件ID" ? "无" :
                      data.title == "源课件名称" ? "无" : '无习题')
                }}</span>
                <el-button type="text" @click="showPaper(data)" size="mini" v-show="data.paperId"
                  class="dataqualificationImageId">预览</el-button>
                <el-button type="text" @click="show(data)" size="mini" v-show="data.qualificationImageId"
                  class="dataqualificationImageId">预览</el-button>
              </template>
            </div>
          </el-tree>   
          <div style="margin-top: 20px;">
            <div style="display: flex">
              <span style="color: red">可见区域：</span>
              <div style="padding-bottom: 1rem" class="seeArea">
                <el-radio-group v-model="radio1" @change="radioChange" :disabled="stu == 'look'">
                  <el-radio label="1">全部区域可见</el-radio>
                  <el-radio label="2">部分区域可见</el-radio>
                  <el-radio label="3">指定机构可见</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div v-show="casaderShow" class="df">
              <el-cascader :options="areatreeList" v-model="areaall" :props="propsareall" placeholder="请选择可见区域"
                style="width: 100%" clearable :disabled="stu == 'look'" @change="change"></el-cascader>
              <el-button style="height: 40px" class="bgc-bv" @click="doall" :disabled="stu == 'look'">全部</el-button>
            </div>
            <div v-if="radio1 == 3">
              <el-select style="width: 400px;margin-bottom: 10px;" size="small" v-model="itemCompanylist" remote
                value-key="compId" :remote-method="getCompanyList1" filterable :disabled="stu == 'look'" clearable
                placeholder="请至少输入两个字搜索" multiple collapse-tags reserve-keyword>
                <el-option v-for="item in CompanyList1" :key="item.compId" :label="item.compName"
                  :value="item"></el-option>
              </el-select>
              <div>
                <template v-if="itemCompanylist.length > 0">
                  <el-tag style="margin: 5px;" v-for="(tag, index) in itemCompanylist" :key="tag.compId"
                    @close="handleClose(tag, index)" :closable="stu !== 'look'">
                    {{ index + 1 }}.{{ tag.compName }}
                  </el-tag>
                </template>
                <template v-else>
                  未选择机构
                </template>
              </div>
            </div>
          </div>
          <div class="flexcb">
            <h3 style="padding-bottom: 20px">课程核算设置</h3>
          </div>
          <el-table ref="multipleTable" :data="tableData" height="300" size="small" tooltip-effect="dark"
            style="width: 100%" :header-cell-style="tableHeader" stripe>
            <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="100px" />
            <el-table-column label="课程方名称" align="left" prop="providerName" show-overflow-tooltip minWidth="150" />
            <el-table-column label="课件数量" align="left" prop="kpointNum" show-overflow-tooltip minWidth="150" />
            <el-table-column label="公共课数量" align="left" prop="publicKpointNum" show-overflow-tooltip minWidth="150" />
            <el-table-column label="数据来源" align="center" show-overflow-tooltip prop="kpointSource" minWidth="120">
              <template slot-scope="scope">{{ pdKpointSource(scope.row.kpointSource) }}</template>
            </el-table-column>
            <el-table-column label="是否纳入核算" align="left" show-overflow-tooltip minWidth="150">
              <template slot-scope="{ row }">
                <el-switch v-model="row.isAccounting" :disabled="stu == 'look'" active-color="#13ce66"
                  inactive-color="#ff4949">
                </el-switch>
                <span :style="{ 'color': row.isAccounting ? '#13ce66' : '#ff4949', 'margin-left': '10px' }">{{
                  row.isAccounting ? '纳入核算' : '不纳入核算' }}</span>
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
          <div style="margin-top: 20px;">
            <span v-show="stu !== 'look'" style="padding-right: 50px">课程：{{ ruleForm.courseName }}</span>
            <el-radio-group v-model="radio" @change="RadioChange" v-show="stu != 'look'">
              <el-radio label="30">通过</el-radio>
              <el-radio label="40">不通过</el-radio>
            </el-radio-group>
            <div style="display: flex; padding-top: 0.5rem" v-show="radioShow">
              <el-input type="textarea" placeholder="请输入不通过原因" v-model="textarea" ref="textarea" maxlength="100"
                show-word-limit />
            </div>
          </div>
          <div style="display: flex; justify-content: center; padding: 20px" v-show="stu != 'look'">
            <el-button @click="back()" class="bgc-bv">取消</el-button>
            <el-button @click="getCheckedNodes" class="bgc-bv">确定</el-button>
          </div>
        </div>
      </div>
    </div>
    <PaperShow ref="PaperShow" />
    <el-dialog :title="'授课讲师：' + teacherName" :visible.sync="dialogVisible" width="40%" top="2%">
      <el-image :src="src" class="imgBoxs" oncontextmenu="return false;" ondragstart="return false;" />
    </el-dialog>
  </div>
</template>
<script>
import List from "@/mixins/List";
import PaperShow from "@/views/resourse/popup/PaperShow";
import { mapGetters } from "vuex";
export default {
  name: "CourseReviewDetails",
  components: {
    PaperShow,
  },
  mixins: [List],
  doNotInit: true,
  components: {
    PaperShow,
  },
  data() {
    return {
      dialogVisible: false,
      src: "",
      teacherName: "",
      data: [],
      defaultProps: {
        id: "courseKpointId",
        children: "children",
      },
      ruleForm: {},
      radio: "",
      textarea: "",
      courseId: "",
      radioShow: false,
      active: this.$route.query.active,
      stu: "",
      casaderShow: false,
      areatreeList: [],
      propsareall: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
        multiple: true,
      },
      isall: false,
      radio1: "",
      areaall: "",
      itemCompanylist: [],//选中的机构
      CompanyList1: [],//搜索的机构
      convertClassHourList: [],//课时时长字典
      ssss: true,
      ssss1: true,
    };
  },
  created() {
    this.courseId = this.$route.query.courseId;
    this.stu = this.$route.query.stu;
    this.getDictionary();
    this.getDetail(this.courseId);
    this.getareatree();
  },
  computed: {
    ...mapGetters({
      userJson: "getUser"
    }),
    // 节课时数换算
    sectionLessonPeriod() {
      return (num) => {
        let res = (num / (~~this.ruleForm.convertClassHour * 60))
        return (parseInt(res * 100) / 100).toFixed(2)
      }
    },
    // 章课时数换算
    chapterLessonPeriod() {
      return (list) => {
        if (list.children?.length > 0) {
          let total = 0
          for (let i = 0; i < list.children.length; i++) {
            const element = list.children[i];
            let res = (element.kpointDuration / (~~this.ruleForm.convertClassHour * 60))
            total += +(parseInt(res * 100) / 100).toFixed(2)
          }
          return total.toFixed(2)
        } else {
          return '0'
        }
      }
    },
    // 章视频时长换算
    chapterDuration() {
      return (list) => {
        if (list.children?.length > 0) {
          let total = 0
          for (let i = 0; i < list.children.length; i++) {
            const element = list.children[i];
            total += element.kpointDuration
          }
          return this.getTime(total)
        } else {
          return '0'
        }
      }
    },
    // 总课时
    assembleClassHourNum() {
      return (num) => {
        let res = (num / (~~this.ruleForm.convertClassHour * 60))
        return (parseInt(res * 100) / 100).toFixed(2)
      }
    },
  },
  methods: {
    // 获取字典
    getDictionary() {
      // 课时时长
      let arr = this.$setDictionary("CONVERT_CLASS_HOUE", "list");
      for (const key in arr) {
        this.convertClassHourList.push({
          label: arr[key],
          value: key,
        });
      }
    },
    // 搜索
    getCompanyList1(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              let obj = {};
              let peon = [...res.data, ...this.itemCompanylist].reduce((cur, next) => {
                obj[next.compId] ? "" : obj[next.compId] = true && cur.push(next);
                return cur;
              }, [])
              this.CompanyList1 = peon || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        let obj = {};
        let peon = [...this.itemCompanylist].reduce((cur, next) => {
          obj[next.compId] ? "" : obj[next.compId] = true && cur.push(next);
          return cur;
        }, [])
        this.CompanyList1 = peon;
      }
    },
    // 保存 - 课时时长
    saveConvertClassHour(){
      this.$post("/run/course/modify/convertClassHour", {
        courseId: this.courseId,
        convertClassHour: this.ruleForm.convertClassHour
      }).then((res) => {
        
      })
      .catch((err) => {
        return;
      });
    },
    // 删除指定机构
    handleClose(tag, index) {
      this.itemCompanylist.splice(index, 1)
    },
    doGoBack() {
      this.$router.push({
        path: "/web/CourseReview",
        query: {
          activeName: "second",
        },
      });
    },
    // 获取课程详情
    getDetail(courseId) {
      this.$post("/run/course/queryCourseDetail", {
        courseId,
      }).then((result) => {
        const data = result.data || {};
        if (result.status === "0") {
          this.ruleForm = {
            courseName: data.courseName, // 课程名称
            context: data.context, //课程详情
            thumbnail: data.logoURL,
            lecturer: data.lecturer,
            trainTypeNamePath: data.trainTypeNamePath,
            postName: data.postName,
            industryNamePath: data.industryNamePath,
            occupationNamePath: data.occupationNamePath,
            trainLevelName: data.trainLevelName,
            auditState: data.auditState,
            durationStr: data.durationStr,
            duration: data.duration,
            remark: data.remark,
            convertClassHour: data?.convertClassHour ?? '45',
            totalClassHours: data.totalClassHours,
            kpointTotalNum: data.kpointTotalNum,
          };
          this.radio = data.auditState;
          this.datas = data.nodes;
          this.textarea = data.auditReason;
          if (this.radio == "40") {
            this.radioShow = true;
          } else {
            this.radioShow = false;
          }
          if (data.areaList.length > 0 || data.compList.length > 0) {
            if (data.areaList.length > 0) {
              this.radio1 = "2";
              this.casaderShow = true;
              this.areaall = data.areaList;
            }
            if (data.compList.length > 0) {
              this.radio1 = "3";
              this.casaderShow = false;
              this.itemCompanylist = data.compList;
              this.CompanyList1 = data.compList;
            }
          } else {
            this.radio1 = "1";
            this.casaderShow = false;
            this.areaall = [];
            this.itemCompanylist = [];
          }
          this.tableData = data.accountingInfoList || []
          this.creatData(data);
        }
      });
    },
    // 手动展开/折叠全部节点
    setAllExpand() {
      let _this = this;
      _this.ssss = !_this.ssss;
      _this.ssss1 = false;
      _this.$nextTick(()=>{
        _this.ssss1 = true
      })
    },
    // 判断:课程核算设置-数据来源的显示字段名称
    pdKpointSource(row) {
      if (row) {
        let arr = row.split(',')
        let str = "";
        for (let i = 0; i < arr.length; i++) {
          str += this.$setDictionary("KPOINT_SOURCE", arr[i]) + ",";
        }
        return str.slice(0, -1)
      }
      // this.$setDictionary("KPOINT_SOURCE", scope.row.kpointSource)
      // 北京晟融机构id;10951，10982 联安众id;的显示数据来源列
      // let compIdArr = ["10981", "10951", "10982"]
      // return compIdArr.includes(this.userJson.compId)
    },
    creatData(data = {}) {
      let list = [];
      this.chapterSchema = data.chapterSchema;
      if (this.chapterSchema) {
        // 样式一 ------> 三层
        data.nodes.forEach((element) => {
          let nodeList = [];
          list.push({
            courseKpointId: element.courseKpointId,
            catalogName: element.catalogName,
            parentNodeId: element.parentNodeId,
            children: nodeList,
            level: 1,
            auditState: element.auditState,

            kpointSource: element.kpointSource,
            showTrySeeBtn: element.showTrySeeBtn,
            rrsTryUrl: element.rrsTryUrl,
            kpointDuration: element.kpointDuration,
          });
          (element.child || []).forEach((obj) => {
            nodeList.push(this.created2dom(obj));
          });
        });
      } else {
        data.nodes.forEach((element) => {
          list.push(this.created2dom(element));
        });
      }
      this.data = list;
      this.mapData(list);
      console.log(this.data)
    },
    created2dom(obj) {
      let children = [];
      // if (obj.paperId || obj.teacherPathName) {
      children = [
        {
          title: "授课讲师",
          catalogName: obj.teacherPathName,
          courseKpointId: obj.courseKpointId,
          sourceCourseKpointId: obj.courseKpointId,
          level: 3,
          qualificationImageId: obj.qualificationImageId,
        },
        {
          title: "课后习题",
          catalogName: obj.paperName,
          courseKpointId: obj.courseKpointId,
          sourceCourseKpointId: obj.courseKpointId,
          level: 3,
          paperId: obj.paperId,
        },
        {
          title: "课件提供企业",
          catalogName: obj.comOwnerName,
          level: 3,
        },
        {
          title: "源课件ID",
          catalogName: obj.resourceSourceId || obj.kpointId,
          level: 3,
        },
        {
          title: "源课件名称",
          catalogName: obj.kpointName,
          level: 3,
        },
      ];
      // }
      let listArr = [];
      if (obj.kpointVideoId) {
        obj.list = listArr;
        listArr.push({
          kpointVideoId: obj.kpointVideoId,
          kpointId: obj.kpointId,
          kpointSource: obj.kpointSource,
          showTrySeeBtn: obj.showTrySeeBtn,
          rrsTryUrl: obj.rrsTryUrl,
        });
      } else if (obj.paperId) {
        obj.list = listArr;
        listArr.push({
          paperId: obj.paperId,
          paperName: obj.paperName,

          kpointSource: obj.kpointSource,
          showTrySeeBtn: obj.showTrySeeBtn,
          rrsTryUrl: obj.rrsTryUrl,
        });
      }
      //判断kpointId存在，paperId存在，带试卷的课件，kpointId不存在，paperId存在是单元测试
      if (obj.kpointId) {
        return {
          catalogName: obj.catalogName,
          parentNodeId: obj.parentNodeId,
          kpointLessonNum: obj.kpointLessonNum,
          level: 2,
          list: listArr,
          children: children,
          auditState: obj.auditState,
          auditStates: obj.auditState,
          section: obj.section,
          courseKpointId: obj.courseKpointId,
          sourceCourseId: this.courseId,
          sourceCourseKpointId: obj.courseKpointId,
          kpointId: obj.kpointId,
          reason: obj.auditState == "40" ? obj.auditReason : "",
          datainput: obj.auditState == "40" ? true : false,
          hidden: obj.hidden,
          kpointDurationStr: obj.kpointDurationStr,
          kpointSource: obj.kpointSource,
          showTrySeeBtn: obj.showTrySeeBtn,
          rrsTryUrl: obj.rrsTryUrl,
          kpointDuration: obj.kpointDuration,
        };
      } else {
        return {
          catalogName: obj.catalogName,
          parentNodeId: obj.parentNodeId,
          kpointLessonNum: obj.kpointLessonNum,
          level: 2,
          list: listArr,
          children: [],
          auditState: obj.auditState,
          auditStates: obj.auditState || "30",
          section: obj.section,
          courseKpointId: obj.courseKpointId,
          sourceCourseId: this.courseId,
          sourceCourseKpointId: obj.courseKpointId,
          kpointId: obj.kpointId,
          reason: obj.auditState == "40" ? obj.auditReason : "",
          datainput: obj.auditState == "40" ? true : false,
          // hidden: obj.hidden,
          hidden: true,
          paperId: obj.paperId,
          paperName: obj.paperName,
          kpointDurationStr: obj.kpointDurationStr,
          kpointSource: obj.kpointSource,
          showTrySeeBtn: obj.showTrySeeBtn,
          rrsTryUrl: obj.rrsTryUrl,
          kpointDuration: obj.kpointDuration,
        };
      }
    },
    change(data) {
      if (data.auditStates == "30") {
        data.datainput = false;
      } else {
        data.datainput = true;
      }
    },
    RadioChange(val) {
      if (val == "40") {
        this.radioShow = true;
      } else {
        this.radioShow = false;
      }
    },
    showPaper(data) {
      this.$refs.PaperShow.showPopUp(data.paperId); // 打开弹窗预览试题
    },
    show(data) {
      this.dialogVisible = true;
      this.teacherName = data.catalogName;
      this.src = data.qualificationImageId;
    },
    // 保存
    getCheckedNodes() {
      let courseWareList = [];
      let stu = true;
      this.data.forEach((item) => {
        if (this.chapterSchema) {
          item.children.forEach((items) => {
            if (items.auditStates == "40") {
              if (items.reason == "") {
                this.$message({
                  message: "请输入课件不通过原因",
                  type: "warning",
                });
                stu = false;
                return;
              }
            }
            courseWareList.push({
              kpointId: items.kpointId,
              paperId: items.paperId,
              auditState: items.auditStates,
              auditReason: items.auditStates == "40" ? items.reason : "",
              currentAuditState: items.auditState,
            });
          });
        } else {
          courseWareList.push({
            kpointId: item.kpointId,
            paperId: item.paperId,
            auditState: item.auditStates,
            auditReason: item.auditStates == "40" ? item.reason : "",
            currentAuditState: item.auditState,
          });
        }
      });
      if (this.radio == "40") {
        if (!this.textarea) {
          this.$message({
            message: "请输入课程不通过原因",
            type: "warning",
          });
          return false;
        }
        if (this.textarea) {
          if (this.$refs.textarea.value.length < 5) {
            this.$message({
              message: "请输入不少于5个字的课程不通过原因",
              type: "warning",
            });
            return false;
          }
        }
      }
      let parmar = {
        courseId: this.courseId,
        auditReason: this.textarea,
        currentAuditState: this.ruleForm.auditState,
        courseWareList: courseWareList,
        auditState: this.radio,
      };
      if (this.radio1 == "1") {
        parmar.areaArr = [];
        parmar.compArr = [];
      }
      if (this.radio1 == "2") {
        if (this.areaall.length === 0) {
          this.$message.warning('请选择可见区域！')
          return
        }
        parmar.areaArr = this.areaall;
        parmar.compArr = [];
      }
      if (this.radio1 == "3") {
        if (this.itemCompanylist.length === 0) {
          this.$message.warning('请选择指定机构！')
          return
        }
        parmar.areaArr = [];
        parmar.compArr = this.itemCompanylist.map(e => e.compId);
      }
      parmar.accountingList = this.tableData.map(e => {
        return {
          providerId: e.providerId,
          isAccounting: e.isAccounting
        }
      })
      console.log(parmar);
      if (!stu) {
        return;
      }
      this.$post("/run/course/auditCourse", parmar)
        .then((ret) => {
          if (ret.status == 0) {
            this.saveConvertClassHour();
            this.$message({
              message: ret.message,
              type: "success",
            });
            setTimeout(() => {
              this.$router.push({
                path: "/web/CourseReview",
                query: {
                  activeName: this.active,
                  refresh: true,
                },
              });
            }, 300);
          }
        })
        .catch((err) => {
          return;
        });
    },
    back() {
      this.$router.push({
        path: "/web/CourseReview",
        query: {
          activeName: this.active,
        },
      });
    },
    showVideo(data) {
      //单元测试打开是题库
      if (data.list[0].kpointVideoId) {        
        this.$store.dispatch("handleVideoUrl", {
          kpointVideoId: data.list[0].kpointVideoId,
          kpointName: data.catalogName,
          kpointId: data.list[0].kpointId,
          courseId: this.courseId,
          kpointSource: data.list[0].kpointSource,
          // kpointVideourl
        });

      }
      if (!data.list[0].kpointVideoId && data.paperId) {
        this.showPaper(data);
      }
    },
    mapData(data) {
      //样式1下
      data.forEach((item, i) => {
        item["chapterNum"] = Number(i) + 1;
        item.children.forEach((el, k) => {
          el["chapterNum"] = Number(i) + 1 + "-" + (Number(k) + 1);
        });
      });

      this.$nextTick(() => {
        this.data = data;
      });
      // console.log(this.data);
    },
    // 批量审核
    batchAudit() {
      this.data.forEach((element, index) => {
        if (this.chapterSchema) {
          if (!element.hidden) {
            if (element.children) {
              element.children.forEach((child) => {
                if (!child.hidden) {
                  child.auditStates = "30";
                  child.datainput = false;
                }
              });
            } else {
              element.auditStates = "30";
              element.datainput = false;
            }
          }
        } else {
          if (!element.hidden) {
            element.auditStates = "30";
            element.datainput = false;
          }
        }
      });
      this.$forceUpdate();
    },
    // 批量审核不通过
    batchAuditPass() {
      this.data.forEach((element, index) => {
        if (this.chapterSchema) {
          if (!element.hidden) {
            if (element.children) {
              element.children.forEach((child) => {
                if (!child.hidden) {
                  child.auditStates = "40";
                  child.datainput = true;
                }
              });
            } else {
              element.auditStates = "40";
              element.datainput = true;
            }
          }
        } else {
          if (!element.hidden) {
            element.auditStates = "40";
            element.datainput = true;
          }
        }
      });
      this.$forceUpdate();
    },
    // 可见区域选择
    radioChange(val) {
      if (val == "2") {
        this.casaderShow = true;
      } else {
        this.casaderShow = false;
      }
    },
    // 区域
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    doall() {
      if (this.isall) {
        this.areaall = [];
        this.isall = false;
      } else {
        this.areaall = this.areatreeList.flatMap((el) => el.value);
        this.isall = true;
      }
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
        (m != "00" ? m + "分" : "") +
        (s != "00" ? s + "秒" : "") || ""
      );
    },
  },
};
</script>
<style lang="less">
.courseBox {
  display: flex;
  flex-wrap: wrap;

  .form-cell {
    width: 50%;
  }
}

.imgbox {
  .el-form-item__content {
    width: 256px !important;
    height: 156px;
  }
}

.form-inline {
  width: 40%;
}

.custom-tree-node {
  display: flex;
  align-items: center;
  padding: 5px 0;

  .el-input {
    margin-left: 15px;
  }
}

.el-tree-node__content {
  height: auto;
}

.textellipsis {
  width: 30rem;
  /* overflow: auto; */
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 20px;
}

.el-textarea {
  height: 100px;

  .el-textarea__inner {
    height: 100px !important;
    min-height: 100px !important;
    resize: none;
  }
}

.limitput {
  .el-input__suffix {
    top: 15px;
  }
}

.el-image {
  display: block;
}

.imgBoxs {
  display: flex;

  >img {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    z-index: 1;
  }
}
</style>
<style lang="less" scoped>
/deep/ .el-switch__label--left.is-active+.el-switch__core {
  background: #ff4949 !important;
}

.el-textarea {
  .el-textarea__inner {
    height: 10rem !important;
    min-height: 200px !important;
  }
}

.Basicinformation {
  display: flex;
  flex-wrap: wrap;

  >div {
    width: 50%;
    padding: 10px 0;
  }

  .information {
    display: flex;
    flex-wrap: wrap;

    .information-item {
      width: 50%;
    }
  }
}

.lessonNum {
  display: flex;
  padding: 1rem;

  span {
    padding: 0 1rem;
  }
}

.lessNumcount {
  display: flex;
  align-items: center;
  padding: 0 2rem;

  span {
    width: 10rem;
  }
}

.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-tree-node {
  .el-form-item {
    margin-bottom: 0px;
  }
}

.qualificationImageId {
  .el-image {
    display: flex;
    height: 500px;
  }
}

.dataqualificationImageId {
  margin: 0 1rem;
}

.imgUpload {
  display: flex;
  justify-content: center;
}

.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}

.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}

.nullIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.level {
  padding: 3px;
  border-radius: 6px;
  color: #333;
  background-color: #e0f2ff;
  min-width: 20px;
  text-align: center;
}
</style>
